import React from 'react';
import { navigate } from 'gatsby';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';
import DefaultLayout from '../../layouts/DefaultLayout';
import Seo from '../../../app/Seo';
import Page from '../../components/Page';

export default function NotFoundPage() {
  const handleGoToHomePage = () => {
    navigate('/');
  };

  return (
    <>
      <Seo title='Page Not Found' />
      <DefaultLayout>
        <Page pageTitle='Requested Page Not Found'>
          <Box sx={{ textAlign: 'center' }}>
            <Button
              variant='outlined'
              size='large'
              startIcon={<HomeIcon />}
              onClick={handleGoToHomePage}
            >
              Go to home page
            </Button>
          </Box>
        </Page>
      </DefaultLayout>
    </>
  );
}
